import * as React from 'react'
import './companyData.scss'
import CompanyElement from './companyElement'
import { openHours, contact, address, data } from './data'

export const CompanyData: React.FC = () => {
  return (
    <div className="footer-company-data">
      <div className="footer-company-data__container">
        <CompanyElement title="KONTAKT" contact={contact} />
        <div className="footer-company-data__container__wrapper">
          <CompanyElement title="BIURO" contact={address} />

          <div className="footer-company-data__container__wrapper__text">{data[0]}</div>
          <div className="footer-company-data__container__wrapper__text">{data[1]}</div>
          <div className="footer-company-data__container__wrapper__text">{data[2]}</div>
          <div className="footer-company-data__container__wrapper__text">{data[3]}</div>
        </div>
        <CompanyElement title="GODZINY OTWARCIA" items={openHours} />
      </div>
    </div>
  )
}
