import * as React from 'react'
import './serviceDescription.scss'

const ServiceDescription: React.FC = () => {
  return (
    <div className="service-description">
      <h2>
        Kompleksowe <br /> usługi geodezyjne
      </h2>
      <p>
        Firma Geo Saw zajmuje się głównie obsługą geodezyjną: budów, dróg, jak również pomiarami sieci uzbrojenia
        terenu.
      </p>
      <p>Posiadamy referencje od firm i instytucji, dla których prowadziliśmy inwestycje.</p>
      <p>Wykonujemy usługi geodezyjne dla firm, instytucji i osób fizycznych.</p>{' '}
      <p>Świadczymy kompleksowe usługi geodezyjne w zakresie:</p>
    </div>
  )
}

export default ServiceDescription
