export const openHours = ['Pon-Pt - 7:30 - 15:30', 'So-Ndz - Zamknięte']
export const address = [
  {
    href:
      'https://www.google.com/maps/place/Geo+Saw.+Us%C5%82ugi+geodezyjno+-+budowlane.+Sawicki+A.+Geodeta+Geodezja+Brzeg/@50.8604703,17.4687809,15z/data=!4m2!3m1!1s0x0:0x2f1e881fa8565566?sa=X&ved=2ahUKEwjtuffWxq36AhXHgSoKHQa9AagQ_BJ6BAg2EAU',
    contact: 'Długa 43, 49-300 Brzeg',
    target: '_blank',
  },
]
export const contact = [
  { href: 'tel:+48602745487  ', contact: ' +48 602 745 487' },
  { href: 'tel:+48660536165', contact: '+48 660 536 165' },
  { href: 'tel:+48774165478', contact: '00 77 416 54 78' },
  { href: 'mailto:geosaw@wp.pl', contact: 'geosaw@wp.pl' },
]

export const data = ['GeoSaw', 'Usługi Geodezyjno-Budowlane ', 'Andrzej Sawicki', 'NIP: 747 102 16 14']
