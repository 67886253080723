import aboutImg2 from '../../images/aboutUs/AboutImg2.jpg'
import aboutImg from '../../images/aboutUs/AboutImg.jpg'

export const title = 'O Firmie'
export const paragraphs = [
  'Biuro usług geodezyjnych Geo Saw istnieje od 2000 roku.',
  'Świadczymy usługi na najwyższym poziomie.',
  'Naszym atutem  jest doświadczenie połączone z nowoczesnym podejściem do pracy.',
  'Posiadamy niezbędne uprawnienia zawodowe, wiedzę techniczną oraz wysokiej klasy sprzęt geodezyjny.',
  'Jesteśmy firmą znaną i sprawdzoną na rynku. Cechuje nas profesjonalne i rzetelne podejście do zleceniodawcy.',
  'Służymy doradztwem  dotyczącym prawa geodezyjnego oraz wsparciem w rozwiązywaniu problemów technicznych w naszej branży.',
  'Swoim działaniem obejmujemy przede wszystkim woj.: opolskie, dolnośkąskie i śląskie.',
]

export const img1 = aboutImg2
export const img2 = aboutImg
export const alt = 'wierka'
