import { IService } from 'model'
import {
  mdiChartTimelineVariant,
  mdiTextureBox,
  mdiDrone,
  mdiMapMarkerPath,
  mdiImageFilterHdr,
  mdiHomeEditOutline,
  mdiHomeCityOutline,
  mdiBorderInside,
  mdiMapSearchOutline,
  mdiTimelineAlertOutline,
} from '@mdi/js'

export const ServiceData: IService[] = [
  { title: 'Obsługa inwestycji kubatorowych', iconPath: mdiTextureBox },
  { title: 'Obsługa obiektów liniowych', iconPath: mdiChartTimelineVariant },
  { title: 'Tyczenie sieci uzbrojenia terenu', iconPath: mdiMapMarkerPath },
  { title: 'Tyczenie domów jednorodzinnych', iconPath: mdiHomeEditOutline },
  { title: 'Mapy do celów projektowych', iconPath: mdiMapSearchOutline },
  { title: 'Pomiary obiętości mas ziemnych', iconPath: mdiImageFilterHdr },
  { title: 'Pomiary wysokości obiektów niedostępnych', iconPath: mdiTimelineAlertOutline },
  { title: 'Dronowe pomiary infrastruktury', iconPath: mdiDrone },
  { title: 'Wznowienia znaków granicznych', iconPath: mdiBorderInside },
  { title: 'Inwentaryzacje powykonawcze budynków', iconPath: mdiHomeCityOutline },
]
