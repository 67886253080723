import rImg2 from '../../images/instalations/Rozdzielna.com.jpg'
import rImg1 from '../../images/instalations/Realizacje.jpg'

export const title = 'Profesjonalna geodezja'
export const paragraphs = [
  `Geo Saw to firma geodezyjna z dużym doświadczeniem i nowoczesnym podejściem do pracy`,
  `Dyspozycyjność, wysoka jakość oraz terminowość wykonywanych usług,  to cechy które nas wyróżniają.`,
  'Swoją pracę wykonujemy przy użyciu nowoczesnego sprzętu: GPSów, niwelatorów kodowych, tachimetrów robotycznych, dronów.',
  'Gwarantujemy doradztwo  i pomoc geodezyjną.',
  'Zapraszamy do  kontaktu i współpracy firmy, instytucje oraz osoby prywatne.',
]

export const img1 = rImg2
export const img2 = rImg1
export const alt = 'rozdzielnia'
