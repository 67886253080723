import * as React from 'react'
import './navbar.scss'
import { HiPhoneIncoming, HiOutlinePhoneIncoming } from 'react-icons/hi'
import { FaRegCalendarAlt } from 'react-icons/fa'
import LogoBlack from '../../icons/logo/logoBlack'
import { pickDay } from './dayPicker'
import { Link } from 'react-scroll'

const NavBar: React.FC = () => {
  return (
    <nav className="navbar">
      <div className="navbar__container">
        <Link
          className="navbar__container__logo"
          activeClass="active"
          to="introductionSection"
          spy={true}
          smooth={true}
          offset={-80}
          duration={1000}
        >
          <LogoBlack height="50px" />
        </Link>
        <a rel="noopener" href="tel:+48602745487" className="navbar__container__menu-icon">
          <HiPhoneIncoming className="hi-phone" />
        </a>
        <div className="navbar__container__nav-menu">
          <a rel="noopener" className="navbar__container__nav-menu__phone" href="tel:+48602745487">
            <HiPhoneIncoming className="icon-phone" /> +48 602 745 487
          </a>
          <a rel="noopener" className="navbar__container__nav-menu__phone" href="tel:+48774165478">
            <HiOutlinePhoneIncoming className="icon-phone" /> 00 77 416 54 78
          </a>
        </div>
        <div className="navbar__container__nav-menu">
          <p className="navbar__container__nav-menu__text">
            <FaRegCalendarAlt className="icon" />
            {pickDay}
          </p>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
