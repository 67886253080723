import * as React from 'react'
import { useIconStyles } from '../styles'
import classnames from 'classnames'

interface ILogoBlackProps extends JSX.IntrinsicAttributes {
  width?: string
  height?: string
  className?: string
  fill?: string
}
const LogoBlack = ({ className, width, height }: ILogoBlackProps) => {
  const classes = useIconStyles()
  return (
    <div className={classnames(className, classes.iconWrapper)}>
      <svg width={width ? width : '220'} height={height ? height : '60'} viewBox="0 0 249.8 54.95">
        <path fill="#fc7324" d="M21.51,54.33A26.67,26.67,0,0,0,26,55V46.84h-.17Z" />
        <circle fill="#fc7324" cx="27.49" cy="24.5" r="6.63" />
        <path fill="#fc7324" d="M29,46.84V55a26.3,26.3,0,0,0,4.47-.62l-4.33-7.49Z" />
        <path
          fill="#fc7324"
          d="M21.29,10.49l-3.16,4.39h0a3.78,3.78,0,0,1,3.64,2.78,8.9,8.9,0,0,1,11.39,0,3.77,3.77,0,0,1,3.63-2.78h0L33.7,10.49Z"
        />
        <path
          fill="#fc7324"
          d="M27.49,0a27.5,27.5,0,0,0-9,53.49l3.84-6.65H18.24a2.41,2.41,0,0,1-2.4-2.4V42H39.18v2.4a2.42,2.42,0,0,1-2.41,2.4H32.63l3.84,6.65A27.5,27.5,0,0,0,27.49,0ZM44.21,33.7a3.79,3.79,0,0,1-3.77,3.77H39.18v2.8H15.81v-2.8H14.55a3.79,3.79,0,0,1-3.77-3.77V18.65a3.78,3.78,0,0,1,3.77-3.77h0l5.06-7.4H35.36l5.06,7.4h0a3.78,3.78,0,0,1,3.77,3.77Z"
        />
        <path
          fill="#fff"
          d="M78,27.35A13.78,13.78,0,0,1,71,15.2,13.78,13.78,0,0,1,78,3a13.94,13.94,0,0,1,7-1.83,14.46,14.46,0,0,1,5.64,1.08,12.64,12.64,0,0,1,4.38,3L92.23,8.16A9.17,9.17,0,0,0,85.05,5.1a10.06,10.06,0,0,0-4.9,1.25,9.43,9.43,0,0,0-3.63,3.57,10.32,10.32,0,0,0-1.35,5.28,10.19,10.19,0,0,0,1.37,5.29A9.51,9.51,0,0,0,80.17,24a9.88,9.88,0,0,0,4.91,1.25A9.34,9.34,0,0,0,92,22.64a6.84,6.84,0,0,0,1.44-2.1,10.49,10.49,0,0,0,.8-2.8H85V14.11H98a12.84,12.84,0,0,1,.23,2.17,14.42,14.42,0,0,1-.81,4.84A11,11,0,0,1,95,25.18a11.86,11.86,0,0,1-4.3,3,14.57,14.57,0,0,1-5.61,1A13.94,13.94,0,0,1,78,27.35Z"
        />
        <path fill="#fff" d="M104.53,1.81h16.23V5.7H108.64v7.55h10.93v3.89H108.64v7.55h12.12v3.89H104.53Z" />
        <path
          fill="#fff"
          d="M132.17,27.31a13.46,13.46,0,0,1-5-5.06,14.08,14.08,0,0,1-1.82-7,14,14,0,0,1,1.82-7,13.42,13.42,0,0,1,5-5.07,13.83,13.83,0,0,1,19.09,5.07,14,14,0,0,1,1.82,7,14.08,14.08,0,0,1-1.82,7.05,14,14,0,0,1-19.09,5.06Zm12-3.31a9.32,9.32,0,0,0,3.51-3.59A10.46,10.46,0,0,0,149,15.2,10.47,10.47,0,0,0,147.67,10a9.32,9.32,0,0,0-3.51-3.59,10,10,0,0,0-9.84,0A9.32,9.32,0,0,0,130.81,10a10.37,10.37,0,0,0-1.3,5.22,10.36,10.36,0,0,0,1.3,5.21A9.32,9.32,0,0,0,134.32,24a10,10,0,0,0,9.84,0Z"
        />
        <path
          fill="#fff"
          d="M170.28,27.24a10.08,10.08,0,0,1-3.7-5.46l3.89-1.57a7.47,7.47,0,0,0,2.13,3.66,5.22,5.22,0,0,0,3.7,1.42,5.58,5.58,0,0,0,3.42-1,3.4,3.4,0,0,0,1.41-2.88,3.59,3.59,0,0,0-1.24-2.79,12.92,12.92,0,0,0-4.26-2.15l-1.69-.6a12.54,12.54,0,0,1-4.54-2.71,6.1,6.1,0,0,1-1.81-4.62,6.7,6.7,0,0,1,1.06-3.66,7.37,7.37,0,0,1,3-2.66,9.54,9.54,0,0,1,4.36-1,8.52,8.52,0,0,1,5.57,1.7,8.64,8.64,0,0,1,2.85,3.83l-3.71,1.57A4.54,4.54,0,0,0,179.14,6,4.79,4.79,0,0,0,176,5a5,5,0,0,0-3.11,1,3,3,0,0,0-1.27,2.47,2.82,2.82,0,0,0,1.14,2.3,12.07,12.07,0,0,0,3.54,1.74l1.72.6a13.52,13.52,0,0,1,5.31,3.11,6.92,6.92,0,0,1,1.87,5.08,7.12,7.12,0,0,1-1.33,4.4,8,8,0,0,1-3.4,2.63,10.91,10.91,0,0,1-4.25.86A9.9,9.9,0,0,1,170.28,27.24Z"
        />
        <path
          fill="#fff"
          d="M198.78,1.81h4.67l10.14,26.77h-4.53L206.6,21.7H195.67l-2.46,6.88h-4.53Zm6.47,16.08-3-8.21-1-3.07H201l-1,3.07-3,8.21Z"
        />
        <path
          fill="#fff"
          d="M214.45,1.81H219l4.27,17.39.44,2.32H224l.56-2.32L229.9,1.81H234l5.16,17.39.56,2.28H240l.45-2.28,4.26-17.39h4.52l-7.06,26.77h-4.34l-5.13-17.76-.6-2.51h-.22l-.64,2.51-5.34,17.76h-4.23Z"
        />
        <path
          fill="#fff"
          d="M71.55,41.44v5.72c0,2.39,1.33,3,2.62,3,1.51,0,2.6-.66,2.6-3V41.44h1V47.1c0,3-1.63,3.92-3.61,3.92s-3.58-1-3.58-3.81V41.44Z"
        />
        <path
          fill="#fff"
          d="M80.05,49a1.7,1.7,0,0,0,1.82,1.31c1.12,0,1.56-.5,1.56-1.2s-.32-1.05-1.74-1.4c-1.85-.45-2.27-1-2.27-1.92s.71-1.86,2.35-1.86a2.21,2.21,0,0,1,2.47,1.94h-.89a1.49,1.49,0,0,0-1.62-1.18c-1.08,0-1.4.53-1.4,1s.31.86,1.61,1.17c2,.48,2.44,1.13,2.44,2.15,0,1.22-1,2-2.53,2A2.41,2.41,0,0,1,79.14,49Z"
        />
        <path fill="#fff" d="M87.93,43.94v.88l-2.85,1.59v-.87Zm-1.94,7v-10h.91v10Z" />
        <path
          fill="#fff"
          d="M94.42,48.91c0,.67,0,1.67,0,2h-.88c0-.18,0-.62,0-1.12a2.05,2.05,0,0,1-2.14,1.28c-1.05,0-2.24-.47-2.24-2.48V44.08H90v4.3c0,.95.29,1.86,1.55,1.86s1.93-.79,1.93-2.63V44.08h.91Z"
        />
        <path
          fill="#fff"
          d="M102,50.37c0,2.19-.94,3.38-3,3.38-1.91,0-2.6-1.07-2.75-2.06h.93A1.73,1.73,0,0,0,99,53c1.67,0,2.11-1.07,2.11-2.73V49.6a2.12,2.12,0,0,1-2.21,1.29c-1.64,0-2.83-1.33-2.83-3.39,0-2.27,1.31-3.58,2.94-3.58,1.35,0,1.93.63,2.1,1.14,0-.36,0-.83.05-1H102c0,.31,0,1.32,0,2Zm-5-2.91c0,1.54.81,2.62,2,2.62,1.6,0,2.07-1.06,2.07-2.72s-.48-2.64-2-2.64S97,45.94,97,47.46Z"
        />
        <path fill="#fff" d="M104.07,40.93H105v1.39h-.91Zm0,3.15H105v6.83h-.91Z" />
        <path
          fill="#fff"
          d="M118.31,50.91h-.77a6.37,6.37,0,0,1-.11-1.41,3.1,3.1,0,0,1-3,1.52c-2.73,0-4.15-2.07-4.15-4.76s1.52-5,4.33-5c2.12,0,3.48,1.12,3.77,2.85h-1a2.65,2.65,0,0,0-2.82-2c-2.3,0-3.28,1.81-3.28,4.07,0,2.07,1,4,3.2,4s2.86-1.49,2.86-3.36v-.1h-2.88v-.86h3.82Z"
        />
        <path
          fill="#fff"
          d="M120.89,47.64c0,1.59.81,2.63,2,2.63a1.76,1.76,0,0,0,1.82-1.18h.92a2.68,2.68,0,0,1-2.77,2c-2.11,0-3-1.77-3-3.51,0-2,1-3.64,3-3.64a2.85,2.85,0,0,1,2.84,3.15,5.33,5.33,0,0,1,0,.57Zm4-.71c0-1.25-.68-2.24-1.93-2.24s-1.91.92-2,2.24Z"
        />
        <path
          fill="#fff"
          d="M133.19,47.48c0,1.93-1.09,3.59-3.15,3.59-1.89,0-3-1.52-3-3.57s1.11-3.58,3.13-3.58C132,43.92,133.19,45.34,133.19,47.48Zm-5.26,0c0,1.57.82,2.78,2.17,2.78s2.14-1.13,2.14-2.77-.76-2.78-2.17-2.78S127.93,45.89,127.93,47.49Z"
        />
        <path
          fill="#fff"
          d="M140.34,40.93v8c0,.65,0,1.27,0,1.95h-.87a10.53,10.53,0,0,1,0-1.07,2.19,2.19,0,0,1-2.23,1.23c-1.76,0-2.83-1.44-2.83-3.5s1.16-3.65,3-3.65c1.29,0,1.83.54,2,.95V40.93Zm-5,6.61c0,1.77.88,2.72,2,2.72,1.71,0,2.14-1.21,2.14-2.9s-.38-2.64-2-2.64C136.15,44.72,135.32,45.74,135.32,47.54Z"
        />
        <path
          fill="#fff"
          d="M142.87,47.64c0,1.59.81,2.63,2,2.63a1.76,1.76,0,0,0,1.82-1.18h.92a2.69,2.69,0,0,1-2.77,2c-2.11,0-2.95-1.77-2.95-3.51,0-2,1-3.64,3-3.64a2.86,2.86,0,0,1,2.84,3.15,5.33,5.33,0,0,1,0,.57Zm4-.71c0-1.25-.68-2.24-1.94-2.24s-1.9.92-2,2.24Z"
        />
        <path fill="#fff" d="M148.69,50.13l4.14-5.25H148.9v-.8h5v.81l-4.09,5.22H154l-.17.8h-5.18Z" />
        <path
          fill="#fff"
          d="M155.85,44.08c1.25,3.61,1.86,5.32,2.07,6h0c.25-.8.77-2.37,2-6h.93l-2.52,7.18c-.71,2-1.21,2.49-2.52,2.49a5.12,5.12,0,0,1-.67,0v-.84a5.47,5.47,0,0,0,.59,0c.93,0,1.25-.44,1.71-1.72l-2.59-7.11Z"
        />
        <path
          fill="#fff"
          d="M163.11,44.08v7.19c0,1.86-.45,2.48-1.78,2.48a3.58,3.58,0,0,1-.42,0v-.8h.29c.9,0,1-.49,1-1.52V44.08Zm-.91-1.76V40.93h.91v1.39Z"
        />
        <path
          fill="#fff"
          d="M165.26,45.8c0-.6,0-1.18,0-1.72h.89c0,.21.05,1,0,1.22a2.17,2.17,0,0,1,2.21-1.38c1.24,0,2.29.73,2.29,2.58v4.41h-.91V46.63c0-1.13-.46-1.88-1.58-1.88-1.47,0-2,1.18-2,2.61v3.55h-.91Z"
        />
        <path
          fill="#fff"
          d="M178.48,47.48c0,1.93-1.09,3.59-3.15,3.59-1.89,0-3.05-1.52-3.05-3.57s1.11-3.58,3.13-3.58C177.26,43.92,178.48,45.34,178.48,47.48Zm-5.26,0c0,1.57.82,2.78,2.17,2.78s2.14-1.13,2.14-2.77-.76-2.78-2.17-2.78S173.22,45.89,173.22,47.49Z"
        />
        <path fill="#fff" d="M183.53,47v.85h-3.48V47Z" />
        <path
          fill="#fff"
          d="M185.49,41.44h3.45c1.9,0,2.86,1,2.86,2.34a2.07,2.07,0,0,1-1.42,2.07,2.37,2.37,0,0,1,1.74,2.31c0,1.84-1.39,2.75-3.11,2.75h-3.52Zm3.21,4c1.52,0,2.1-.58,2.1-1.6s-.63-1.57-1.86-1.57h-2.5v3.17Zm-2.26,4.62H189c1.22,0,2.16-.6,2.16-1.89,0-1.13-.72-1.89-2.49-1.89h-2.19Z"
        />
        <path
          fill="#fff"
          d="M199.09,48.91c0,.67,0,1.67,0,2h-.88a9.66,9.66,0,0,1,0-1.12A2.06,2.06,0,0,1,196,51.07c-1.06,0-2.25-.47-2.25-2.48V44.08h.9v4.3c0,.95.3,1.86,1.55,1.86,1.41,0,1.94-.79,1.94-2.63V44.08h.91Z"
        />
        <path
          fill="#fff"
          d="M206.68,40.93v8c0,.65,0,1.27,0,1.95h-.88c0-.18,0-.76,0-1.07a2.2,2.2,0,0,1-2.23,1.23c-1.77,0-2.84-1.44-2.84-3.5s1.16-3.65,3-3.65c1.3,0,1.84.54,2,.95V40.93Zm-5,6.61c0,1.77.89,2.72,2,2.72,1.7,0,2.13-1.21,2.13-2.9s-.37-2.64-2-2.64C202.5,44.72,201.66,45.74,201.66,47.54Z"
        />
        <path
          fill="#fff"
          d="M214.49,47.48c0,1.93-1.08,3.59-3.14,3.59-1.9,0-3.06-1.52-3.06-3.57s1.11-3.58,3.13-3.58C213.27,43.92,214.49,45.34,214.49,47.48Zm-5.25,0c0,1.57.81,2.78,2.16,2.78s2.14-1.13,2.14-2.77-.76-2.78-2.17-2.78S209.24,45.89,209.24,47.49Z"
        />
        <path
          fill="#fff"
          d="M216.32,44.08c.93,3.38,1.47,5.31,1.59,6h0c.12-.59.71-2.53,1.73-6h.93c1.18,4.06,1.53,5.23,1.65,5.8h0c.18-.74.48-1.77,1.65-5.8h.93l-2.13,6.83h-1c-.7-2.45-1.48-5.12-1.62-5.79h0c-.13.7-.83,3-1.66,5.79h-1.05l-2-6.83Z"
        />
        <path fill="#fff" d="M226.15,50.91v-10h.91v10Z" />
        <path
          fill="#fff"
          d="M234,49.52a9,9,0,0,0,.1,1.39h-.87a3.7,3.7,0,0,1-.12-1,2.19,2.19,0,0,1-2.18,1.14A2.05,2.05,0,0,1,228.62,49c0-1.51,1.18-2.2,3.22-2.2h1.23v-.64c0-.65-.2-1.48-1.63-1.48-1.26,0-1.46.65-1.6,1.2H229c.08-.84.6-2,2.52-2,1.61,0,2.5.67,2.5,2.19Zm-.89-2h-1.23c-1.44,0-2.29.38-2.29,1.46A1.33,1.33,0,0,0,231,50.31c1.82,0,2.06-1.21,2.06-2.58Z"
        />
        <path
          fill="#fff"
          d="M236.12,45.8c0-.6,0-1.18,0-1.72H237c0,.21,0,1,0,1.22a2.18,2.18,0,0,1,2.21-1.38c1.24,0,2.29.73,2.29,2.58v4.41h-.91V46.63c0-1.13-.46-1.88-1.58-1.88-1.47,0-2,1.18-2,2.61v3.55h-.91Z"
        />
        <path
          fill="#fff"
          d="M244.06,47.64c0,1.59.81,2.63,2.05,2.63a1.78,1.78,0,0,0,1.83-1.18h.92a2.7,2.7,0,0,1-2.78,2c-2.1,0-2.94-1.77-2.94-3.51,0-2,1-3.64,3-3.64A2.85,2.85,0,0,1,249,47.07c0,.2,0,.38,0,.57Zm4-.71c0-1.25-.67-2.24-1.93-2.24s-1.9.92-2,2.24Z"
        />
      </svg>
    </div>
  )
}
export default LogoBlack
