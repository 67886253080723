import * as React from 'react'
import { useIconStyles } from '../styles'
import classnames from 'classnames'

interface ILogoProps extends JSX.IntrinsicAttributes {
  width?: string
  height?: string
  className?: string
  fill?: string
}
const Logo = ({ className, width, height }: ILogoProps) => {
  const classes = useIconStyles()
  return (
    <div className={classnames(className, classes.iconWrapper)}>
      <svg width={width ? width : '60'} height={height ? height : '60'} viewBox="0 0 54.99 54.95">
        <path d="M21.51 54.33A26.67 26.67 0 0026 55v-8.16h-.17z" fill="#fc7324" className="cls-1"></path>
        <circle cx="27.49" cy="24.5" r="6.63" fill="#fc7324"></circle>
        <path
          d="M29 46.84V55a26.3 26.3 0 004.47-.62l-4.33-7.49zM21.29 10.49l-3.16 4.39a3.78 3.78 0 013.64 2.78 8.9 8.9 0 0111.39 0 3.77 3.77 0 013.63-2.78l-3.09-4.39z"
          fill="#fc7324"
        ></path>
        <path
          d="M27.49 0a27.5 27.5 0 00-9 53.49l3.84-6.65h-4.09a2.41 2.41 0 01-2.4-2.4V42h23.34v2.4a2.42 2.42 0 01-2.41 2.4h-4.14l3.84 6.65A27.5 27.5 0 0027.49 0zm16.72 33.7a3.79 3.79 0 01-3.77 3.77h-1.26v2.8H15.81v-2.8h-1.26a3.79 3.79 0 01-3.77-3.77V18.65a3.78 3.78 0 013.77-3.77l5.06-7.4h15.75l5.06 7.4a3.78 3.78 0 013.77 3.77z"
          fill="#fc7324"
        ></path>
      </svg>
    </div>
  )
}
export default Logo
